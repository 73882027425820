.background {
    background-image: url("./background.png");
    background-position: center;
    background-size: cover;
    height: 100%;
    overflow-x: hidden;
}

.background_gradient{
    background-image: linear-gradient(rgba(28, 28, 30, 0.1), rgba(30, 29, 28, 1));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.landing_button{
    color: #FF7600;
    border: 3px solid #FF7600;
    font-family: "Source Sans Pro";
    font-weight: bold;   
    padding: 8px 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #FF7600;
    text-shadow: 0px 0px 1px #FF7600;
    transition: 0.1s ease-in;
}

.landing_button:hover{
    background: #FF7600;
    color: #FFF;
    text-decoration: none;
}

.logo{
    vertical-align: middle;
    width: 150px;
}

.logo_text{
    cursor: pointer;
    color: #FFF;
    display: inline-block;
    font-family: Century Gothic;
    font-size: 58px;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
    vertical-align: middle;
}

.home_for_all_gamers {
    color: #FFF;
    font-family: Century Gothic;
    font-size: 60px;
    text-align: center;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.landing-description-margin{
    margin: auto;
    margin-top: 13vh;
}

@media screen and (min-width: 768px) {
    .view-height{
        height: 100vh;
    }
}

@media screen and (max-width: 768px) {
    .landing-description-margin{
        margin-top: 50px;
    }
    .landing-page-scrolldown{
        position: absolute;
        left: 50%;
        bottom: -20px;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}